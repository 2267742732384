import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import html2canvas from 'html2canvas';
import { ChromePicker } from 'react-color';
import { FaEyeDropper } from 'react-icons/fa'; 
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Moveable from 'react-moveable';


const Model = ({ url, scale, exposure, lightColor }) => {
  const gltf = useGLTF(url);
  return (
    <>
      <ambientLight intensity={exposure} color={lightColor} />
      <primitive object={gltf.scene} scale={scale} />
    </>
  );
};

const GLBViewerContent = ({ fileUrl, scale, exposure, lightColor }) => {
  return (
    <>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      {fileUrl && <Model url={fileUrl} scale={scale} exposure={exposure} lightColor={lightColor} />}
      <OrbitControls
        enablePan={false}
        enableRotate={true}
        mouseButtons={{ RIGHT: THREE.MOUSE.ROTATE }}
        minDistance={0.1} // Prevent clipping by setting minimum zoom distance
        maxDistance={10000} // Set a reasonable maximum distance
      />
    </>
  );
};

const Modal = ({ isOpen, onClose, onExport }) => {
  const [fileName, setFileName] = useState('File Name');
  const [exportFormat, setExportFormat] = useState('png');
  const [transparentBg, setTransparentBg] = useState(false);

  if (!isOpen) return null;

  const handleExportClick = () => {
    onExport(fileName, exportFormat, transparentBg);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Export Options</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <div className="form-group input-group">
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
          <select
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
          >
            <option value="png">PNG</option>
            <option value="jpeg">JPEG</option>
            <option value="webp">WebP</option>
          </select>
        </div>
        <div className="checkbox-group">
          <input
            type="checkbox"
            checked={transparentBg}
            onChange={(e) => setTransparentBg(e.target.checked)}
          />
          <label>Transparent Background</label>
        </div>
        {/* <div className="form-group">
          <button className="export-location-button">
            <img src="Folder Icon.png" alt="icon" /> Select Export Location
          </button>
        </div> */}
        <button className="export-button" onClick={handleExportClick}>Export Canvas</button>
      </div>
    </div>
  );
};

const MoveableContainer = React.forwardRef(({
  initialPosition = { x: 200, y: 200 },
  size,
  initialRotation = 0,
  onUpdate,
  onClick,
  onDoubleClick,
  children,
  isSelected,
  zIndex,
  verticalGuidelines = [],
  horizontalGuidelines = [],
  elementGuidelines = [],
  isTextbox = false,  // New prop to identify if this is a textbox
}, ref) => {
  const targetRef = React.useRef(null);
  const [position, setPosition] = React.useState(initialPosition);
  const [rotation, setRotation] = React.useState(initialRotation);
  const [keepRatio, setKeepRatio] = React.useState(false);
  const [isSpawned, setIsSpawned] = React.useState(true);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  React.useEffect(() => {
    if (onUpdate) {
      onUpdate({ position, size, rotation });
    }
  }, [position, size, rotation, onUpdate]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSpawned(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  const handleDoubleClick = () => {
    if (isTextbox) {
      setIsEditing(true);
      setTimeout(() => {
        const textarea = targetRef.current.querySelector('textarea');
        if (textarea) {
          textarea.focus();
        }
      }, 0);
    }
  };

  return (
    <>
      <div
        ref={(el) => {
          targetRef.current = el;
          if (ref) ref.current = el;
        }}
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: `${size.width}px`,
          height: `${size.height}px`,
          transform: `rotate(${rotation}deg)`,
          zIndex: zIndex,
          pointerEvents: isEditing ? 'auto' : 'auto', // Always allow pointer events
          userSelect: isEditing ? 'text' : 'none', // Allow text selection only in editing mode
          overflow: 'hidden',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={(e) => {
          if (!isEditing) {
            e.stopPropagation();  // Prevent other interactions when not editing
            if (onClick) onClick();
          }
        }}
        onDoubleClick={handleDoubleClick} // Handle double-click for textboxes
        onBlur={() => {
          if (isTextbox) setIsEditing(false); // Disable editing mode on blur if it's a textbox
        }}
        contentEditable={isEditing && isTextbox}  // Make content editable only when editing a textbox
      >
        {children}
      </div>
      {(isSelected || isSpawned || isHovered) && (
        <Moveable
          target={targetRef.current}
          draggable={!isEditing}  // Disable dragging when editing
          resizable={!isEditing}  // Disable resizing when editing
          rotatable={!isEditing}  // Disable rotating when editing
          keepRatio={keepRatio}
          snappable={true}
          snapThreshold={5}
          snapCenter={true}
          snapHorizontal={true}
          snapVertical={true}
          snapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          elementSnapDirections={{
            top: true,
            left: true,
            bottom: true,
            right: true,
            center: true,
            middle: true,
          }}
          maxSnapElementGuidelineDistance={70}
          verticalGuidelines={verticalGuidelines}
          horizontalGuidelines={horizontalGuidelines}
          elementGuidelines={elementGuidelines}
          onResizeStart={({ direction }) => {
            setKeepRatio(direction[0] !== 0 && direction[1] !== 0);
          }}
          onResize={e => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          onDrag={e => {
            e.target.style.transform = e.transform;
          }}
          onRotate={e => {
            e.target.style.transform = e.drag.transform;
          }}
          renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
          rotationPosition={"top"}
          throttleDrag={0}
          throttleResize={0}
          throttleRotate={0}
        />
      )}
    </>
  );
});


const CustomChromePicker = ({ color, onChange, onChangeComplete, onEyedropper }) => {
  return (
    <div style={{ position: 'relative' }}>
      <ChromePicker
        color={color}
        onChange={onChange}
        onChangeComplete={onChangeComplete}
        styles={{
          default: {
            picker: {
              boxShadow: 'none',
              backgroundColor: '#252627',
              border: '2px solid #4B4C4D',
              borderRadius: '8px',
              width: '260px',
              padding: '10px',
            },
            saturation: {
              borderRadius: '4px',
            },
            hue: {
              height: '10px',
              borderRadius: '4px',
              width: '180px',
            },
            swatch: {
              width: '28px',
              height: '28px',
              borderRadius: '40px',
              left: '220px',
              bottom: '7px',
            },
            alpha: {
              height: '10px',
              borderRadius: '4px',
              width: '180px',
            },
          },
        }}
      />
      <button
        onClick={onEyedropper}
        style={{
          position: 'absolute',
          top: '172px',
          left: '12px',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '4px',
          padding: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
        }}
      >
        <FaEyeDropper color="#2C2F33" size={16} />
      </button>
    </div>
  );
};

const LayerPanel = ({ layers, onSelectLayer, onMoveLayer, onRenameLayer, onToggleVisibility }) => {
  const [, drop] = useDrop({ accept: 'layer' });

  return (
    <div ref={drop} className="layers-panel">
      {layers.map((layer, index) => (
        <LayerItem
          key={layer.id}
          index={index}
          layer={layer}
          onSelectLayer={onSelectLayer}
          onMoveLayer={onMoveLayer}
          onRenameLayer={onRenameLayer}
          onToggleVisibility={onToggleVisibility}
        />
      ))}
    </div>
  );
};

const LayerItem = ({ layer, index, onSelectLayer, onMoveLayer, onRenameLayer, onToggleVisibility }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [newName, setNewName] = useState(layer.name);

  const [, drag] = useDrag({
    type: 'layer',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'layer',
    hover: (item) => {
      if (item.index !== index) {
        onMoveLayer(item.index, index);
        item.index = index;
      }
    },
  });

  const handleVisibilityToggle = (e) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
    onToggleVisibility(layer.id, !isVisible);
  };

  const handleRename = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onRenameLayer(layer.id, newName);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onRenameLayer(layer.id, newName);
    }
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="layer-item"
      onClick={() => !isEditing && onSelectLayer(layer.id)}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        opacity: isVisible ? 1 : 0.5,
      }}
    >
      <div style={{ flexGrow: 1, marginRight: '10px' }}>
        {isEditing ? (
          <input
            type="text"
            value={newName}
            onChange={handleNameChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            onClick={(e) => e.stopPropagation()}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              color: 'black',
            }}
          />
        ) : (
          <span>{layer.name}</span>
        )}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleRename(e);
          }}
        >
          <img src="Pencil-Icon-White.png" className="edit-name" alt="Edit Layer Name" />
        </button>
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            marginLeft: '10px',
          }}
          onClick={handleVisibilityToggle}
        >
          <img src={isVisible ? 'Eye-Icon-ON.png' : 'Eye-Icon-OFF.png'} className="hide-layer-item" alt="Toggle Visibility" />
        </button>
      </div>
    </div>
  );
};

const reorderZIndex = (items, orderedLayers) => {
  return items.map((item) => {
    const layerIndex = orderedLayers.findIndex((layer) => layer.id === item.id);
    return { ...item, zIndex: orderedLayers.length - layerIndex };
  });
};

const getHighestZIndex = (layers) => {
  return layers.reduce((max, layer) => Math.max(max, layer.zIndex || 0), 0);
};

const ThreeCanvas = () => {
  const [bgFiles, setBgFiles] = useState([]);
  const [imageButtons, setImageButtons] = useState([
    { id: '1', name: 'Shadow 1', thumbnailSrc: 'Shadow-Icon-1.png', fileSrc: 'Shadow-1.png' },
    { id: '2', name: 'Shadow 2', thumbnailSrc: 'Shadow-Icon-2.png', fileSrc: 'Shadow-2.png' },
    { id: '3', name: 'Shadow 3', thumbnailSrc: 'Shadow-Icon-3.png', fileSrc: 'Shadow-3.png' },
    { id: '4', name: 'Shadow 4', thumbnailSrc: 'Shadow-Icon-4.png', fileSrc: 'Shadow-4.png' },
    { id: '5', name: 'Shadow 5', thumbnailSrc: 'Shadow-Icon-5.png', fileSrc: 'Shadow-5.png' },
    { id: '6', name: 'Shadow 6', thumbnailSrc: 'Shadow-Icon-6.png', fileSrc: 'Shadow-6.png' },
    { id: '7', name: 'Shadow 7', thumbnailSrc: 'Shadow-Icon-7.png', fileSrc: 'Shadow-7.png' },
    { id: '8', name: 'Shadow 8', thumbnailSrc: 'Shadow-Icon-8.png', fileSrc: 'Shadow-8.png' },
    { id: '9', name: 'Shadow 9', thumbnailSrc: 'Shadow-Icon-9.png', fileSrc: 'Shadow-9.png' },
    { id: '10', name: 'Shadow 10', thumbnailSrc: 'Shadow-Icon-10.png', fileSrc: 'Shadow-10.png' },
  ]);
  const [modelFiles, setModelFiles] = useState([]);
  const [textBoxes, setTextBoxes] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: '1080px', height: '1080px' });
  const [zoom, setZoom] = useState(0.7);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [fontSize, setFontSize] = useState('16px');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [shapeColor, setShapeColor] = useState('#ff0000');
  const [shapeOpacity, setShapeOpacity] = useState(1);
  const [fontColor, setFontColor] = useState('rgba(0, 0, 0, 1)');
  const [textOpacity, setTextOpacity] = useState(1);
  const [layers, setLayers] = useState([]);

  const [verticalGuidelines, setVerticalGuidelines] = useState([]);
  const [horizontalGuidelines, setHorizontalGuidelines] = useState([]);
  const moveableRefs = useRef([]);

  const canvasRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const bgInputRef = useRef();
  const modelInputRef = useRef();
  const captureDivRef = useRef();
  const canvasScaleRef = useRef(1);

  const sidebarRef1 = useRef();
  const sidebarRef2 = useRef();
  const sidebarRef3 = useRef();
  const sidebarRef4 = useRef();
  const sidebarRef5 = useRef();
  const sidebarRef6 = useRef();

  const updateCenterGuidelines = useCallback(() => {
    const canvasWidth = parseInt(canvasSize.width, 10);
    const canvasHeight = parseInt(canvasSize.height, 10);
    setVerticalGuidelines([canvasWidth / 2]);
    setHorizontalGuidelines([canvasHeight / 2]);
  }, [canvasSize]);

  useEffect(() => {
    updateCenterGuidelines();
  }, [canvasSize, updateCenterGuidelines]);

  const getElementGuidelines = () => {
    return moveableRefs.current.map(ref => ref.current).filter(ref => ref !== null);
    
};


  const getCanvasCenterPosition = () => {
    const canvasWidth = parseInt(canvasSize.width, 10);
    const canvasHeight = parseInt(canvasSize.height, 10);
    return {
      x: canvasWidth / 2,
      y: canvasHeight / 2,
    };
  };


  const handleBgUpload = (event) => {
  const files = Array.from(event.target.files);
  files.forEach((file, index) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const newBgFile = {
        id: `bg-${Date.now()}-${index}`,
        name: `Image ${index + 1}`,
        url: img.src,
        position: { x: 100, y: 100 },
        size: { width: img.width * 0.5, height: img.height * 0.5 }, // Set to 50% size
        zIndex: getHighestZIndex(layers) + 1,
      };
      setBgFiles((prev) => {
        const updatedFiles = prev.map(file => ({ ...file, isSelected: false }));
        return [...updatedFiles, { ...newBgFile, isSelected: true }];
      });
      setSelectedContainer(newBgFile.id);
      setLayers((prev) => [newBgFile, ...prev]);
    };
    img.onerror = () => {
      console.error("Failed to load image.");
    };
  });
  bgInputRef.current.value = null;
};

const handleImageButtonClick = (image) => {
  const img = new Image();
  img.src = image.fileSrc;
  img.onload = () => {
    const initialPosition = getCanvasCenterPosition();
    const newBgFile = {
      id: `bg-${Date.now()}`,
      name: image.name,
      url: img.src,
      position: initialPosition, // Set the initial position to the center
      size: { width: img.width * 0.5, height: img.height * 0.5 }, // Set to 50% size
      zIndex: getHighestZIndex(layers) + 1,
    };
    setBgFiles((prev) => {
      const updatedFiles = prev.map(file => ({ ...file, isSelected: false }));
      return [...updatedFiles, { ...newBgFile, isSelected: true }];
    });
    setSelectedContainer(newBgFile.id);
    setLayers((prev) => [newBgFile, ...prev]);
  };
};

  const handleModelUpload = (event) => {
    const files = Array.from(event.target.files);
    const newModelFiles = files.map((file, index) => ({
      id: `model-${Date.now()}-${index}`,
      name: `Model ${index + 1}`,
      url: URL.createObjectURL(file),
      position: { x: 100, y: 100 },
      size: { width: 400, height: 400 },
      exposure: 1,
      lightColor: '#ffffff',
      zIndex: getHighestZIndex(layers) + 1,
    }));
    setModelFiles((prev) => {
      const updatedFiles = prev.map(file => ({ ...file, isSelected: false }));
      return [...updatedFiles, ...newModelFiles.map((model, index) => ({ ...model, isSelected: index === 0 }))];
    });
    setSelectedContainer(newModelFiles[0].id);
    setLayers((prev) => [...newModelFiles, ...prev]);
    modelInputRef.current.value = null;
  };

  const handleClearCanvas = () => {
    setBgFiles([]);
    setModelFiles([]);
    setTextBoxes([]);
    setShapes([]);
    setSelectedContainer(null);
    setLayers([]);
  };

  const handleDownloadCanvas = () => {
    setIsModalOpen(true); 
};

const handleExport = (fileName, exportFormat, transparentBg) => {
  const captureDiv = captureDivRef.current;
  if (captureDiv) {
    // Parse width and height to use actual numbers
    const width = parseInt(canvasSize.width, 10);
    const height = parseInt(canvasSize.height, 10);

    // Store original background color
    const originalBackgroundColor = captureDiv.style.backgroundColor;

    // Temporarily set background color based on the checkbox value
    if (transparentBg) {
      captureDiv.style.backgroundColor = 'transparent';
    }

    // Save the current zoom level and temporarily reset it for export
    const originalZoom = zoom;
    const originalTransform = captureDiv.style.transform;

    // Temporarily set the zoom to 100% for exporting
    captureDiv.style.transform = 'scale(1)';
    captureDiv.style.transformOrigin = 'top left';

    // Update each moveable element's inline style with its current position, size, and rotation
    moveableRefs.current.forEach(ref => {
      if (ref && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const canvasRect = captureDiv.getBoundingClientRect();
        const translateX = rect.left - canvasRect.left;
        const translateY = rect.top - canvasRect.top;

        const style = window.getComputedStyle(ref.current);
        const transformMatrix = new DOMMatrix(style.transform);
        const rotationAngle = Math.atan2(transformMatrix.m21, transformMatrix.m11);

        ref.current.style.position = 'absolute';
        ref.current.style.left = `${translateX}px`;
        ref.current.style.top = `${translateY}px`;
        ref.current.style.width = `${rect.width}px`;
        ref.current.style.height = `${rect.height}px`;
        ref.current.style.transform = `translate(0, 0) rotate(${rotationAngle}rad)`;
      }
    });

    html2canvas(captureDiv, {
      width: width,
      height: height,
      scale: canvasScaleRef.current, // Apply scale based on canvasScaleRef, which should be set to 1 during export
      useCORS: true,
      backgroundColor: transparentBg ? null : originalBackgroundColor
    }).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL(`image/${exportFormat}`);
      link.download = `${fileName}.${exportFormat}`;
      link.click();

      // Restore original background color after export
      captureDiv.style.backgroundColor = originalBackgroundColor;

      // Restore the zoom level and transformation after export
      captureDiv.style.transform = originalTransform;
    });

    // Restore original transform settings for viewing
    captureDiv.style.transformOrigin = 'center';
  }
};



  const handleDeleteSelected = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      const isEditing =
        textBoxes.some(textBox => textBox.isEditing) ||
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA' ||
        sidebarRef1.current.contains(document.activeElement) ||
        sidebarRef2.current.contains(document.activeElement) ||
        sidebarRef3.current.contains(document.activeElement) ||
        sidebarRef4.current.contains(document.activeElement) ||
        sidebarRef5.current.contains(document.activeElement) ||
        sidebarRef6.current.contains(document.activeElement);

      if (!isEditing && selectedContainer) {
        setBgFiles((prev) => prev.filter((file) => file.id !== selectedContainer));
        setModelFiles((prev) => prev.filter((file) => file.id !== selectedContainer));
        setTextBoxes((prev) => prev.filter((box) => box.id !== selectedContainer));
        setShapes((prev) => prev.filter((shape) => shape.id !== selectedContainer));
        setLayers((prev) => prev.filter((layer) => layer.id !== selectedContainer));
        setSelectedContainer(null);
      }
    }
  };

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const canvas = canvasRef.current;

    if (canvas) {
      canvas.addEventListener('contextmenu', handleContextMenu);
    }

    window.addEventListener('keydown', handleDeleteSelected);

    return () => {
      if (canvas) {
        canvas.removeEventListener('contextmenu', handleContextMenu);
      }
      window.removeEventListener('keydown', handleDeleteSelected);
    };
  }, [selectedContainer, textBoxes]);

  const handleCanvasSizeChange = (e) => {
    const { name, value } = e.target;
    if (value.match(/^\d+$/)) {
        setCanvasSize((prevSize) => ({ ...prevSize, [name]: `${value}px` }));
    }
};

  const handleInputFocus = (e) => {
    e.target.select();
  };

  const handleZoomChange = (e) => {
    setZoom(e.target.value);
  };

  const toggleSidebar = (sidebarId) => {
    if (activeSidebar === sidebarId) {
      clearActiveState();
      setActiveSidebar(null);
    } else {
      openSidebar(sidebarId);
    }
  };

  const clearActiveState = () => {
    const toggleButtons = document.querySelectorAll('.navbar .toggle-button');
    toggleButtons.forEach(button => button.classList.remove('active'));
  };

  const openSidebar = (sidebarId) => {
    setActiveSidebar(sidebarId);
    clearActiveState();
    const toggleButton = document.querySelector(`.toggle-button-${sidebarId}`);
    if (toggleButton) {
      toggleButton.classList.add('active');
    }
  };

  const addTextBox = () => {
    const initialPosition = getCanvasCenterPosition(); 
    const newTextBox = {
      id: `text-${Date.now()}`,
      name: `Text Box`,
      position: initialPosition,
      size: { width: 200, height: 100 },
      content: 'Insert text here',
      isSelected: true,
      isEditing: false,
      fontSize: '16px',
      fontFamily: 'Arial',
      color: `rgba(0, 0, 0, ${textOpacity})`,
      zIndex: getHighestZIndex(layers) + 1,
    };
    setTextBoxes((prev) => {
      const updatedTextBoxes = prev.map(textBox => ({ ...textBox, isSelected: false }));
      return [...updatedTextBoxes, newTextBox];
    });
    setSelectedContainer(newTextBox.id);
    setFontSize(newTextBox.fontSize);
    setFontFamily(newTextBox.fontFamily);
    setFontColor(newTextBox.color);
    setTextOpacity(1);
    setLayers((prev) => [newTextBox, ...prev]);
    openSidebar(4);
  };

  const handleTextChange = (id, newText) => {
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      newTextBoxes[index].content = newText;
      return newTextBoxes;
    });
  };

  const handleFontChange = (id, property, value) => {
    setTextBoxes((prev) => {
      const newTextBoxes = [...prev];
      const index = newTextBoxes.findIndex((box) => box.id === id);
      newTextBoxes[index][property] = value;
      return newTextBoxes;
    });
  };

  const handleFontColorChange = (color) => {
    if (selectedContainer) {
      setTextBoxes((prev) => {
        const newTextBoxes = [...prev];
        const index = newTextBoxes.findIndex((box) => box.id === selectedContainer);
        if (index !== -1) {
          const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
          newTextBoxes[index].color = rgbaColor;
        }
        return newTextBoxes;
      });
      setFontColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
      setTextOpacity(color.rgb.a);
    }
  };

  const addShape = (shapeType) => {
    const initialPosition = getCanvasCenterPosition();
    const newShape = {
      id: `shape-${Date.now()}`,
      name: `${shapeType.charAt(0).toUpperCase() + shapeType.slice(1)} Shape`,
      type: shapeType,
      position: initialPosition, // Set the initial position to the center
      size: { width: 100, height: 100 },
      color: '#ff0000',
      opacity: 1,
      isSelected: true,
      zIndex: getHighestZIndex(layers) + 1,
    };
    setShapes((prev) => {
      const updatedShapes = prev.map(shape => ({ ...shape, isSelected: false }));
      return [...updatedShapes, newShape];
    });
    setSelectedContainer(newShape.id);
    setShapeColor(newShape.color);
    setShapeOpacity(newShape.opacity);
    setLayers((prev) => [newShape, ...prev]);
    openSidebar(5);
  };

  const handleShapeChange = (id, property, value) => {
    setShapes((prev) => {
      const newShapes = [...prev];
      const index = newShapes.findIndex((shape) => shape.id === id);
      newShapes[index][property] = value;
      return newShapes;
    });
  };

  const handleObjectClick = (id) => {
    setSelectedContainer(id);
    setBgFiles((prev) =>
      prev.map((file) => ({
        ...file,
        isSelected: file.id === id,
      }))
    );
    setModelFiles((prev) =>
      prev.map((file) => ({
        ...file,
        isSelected: file.id === id,
      }))
    );
    setTextBoxes((prev) => {
      const updatedTextBoxes = prev.map((box) => ({
        ...box,
        isSelected: box.id === id,
        isEditing: false,
      }));
      const selectedTextBox = updatedTextBoxes.find(box => box.id === id);
      if (selectedTextBox) {
        setFontSize(selectedTextBox.fontSize);
        setFontFamily(selectedTextBox.fontFamily);
        setFontColor(selectedTextBox.color);
        openSidebar(4);
      }
      return updatedTextBoxes;
    });
    setShapes((prev) => {
      const updatedShapes = prev.map((shape) => ({
        ...shape,
        isSelected: shape.id === id,
      }));
      const selectedShape = updatedShapes.find(shape => shape.id === id);
      if (selectedShape) {
        setShapeColor(selectedShape.color);
        setShapeOpacity(selectedShape.opacity);
        openSidebar(5);
      }
      return updatedShapes;
    });

    const selectedBgFile = bgFiles.find(file => file.id === id);
    const selectedModelFile = modelFiles.find(file => file.id === id);

    if (selectedBgFile) {
      openSidebar(2);
    } else if (selectedModelFile) {
      openSidebar(3);
    }
  };

  const handleCanvasClick = (e) => {
    if (e.target === e.currentTarget) {
      setSelectedContainer(null);
      setBgFiles((prev) => prev.map(file => ({ ...file, isSelected: false })));
      setModelFiles((prev) => prev.map(file => ({ ...file, isSelected: false })));
      setTextBoxes((prev) => prev.map(box => ({ ...box, isSelected: false, isEditing: false })));
      setShapes((prev) => prev.map(shape => ({ ...shape, isSelected: false })));
    }
  };

  const handleTextBoxDoubleClick = (id) => {
    setTextBoxes((prev) =>
      prev.map((box) => ({
        ...box,
        isEditing: box.id === id,
      }))
    );

    setTimeout(() => {
      const textarea = document.getElementById(`textarea-${id}`);
      if (textarea) {
        textarea.focus();
      }
    }, 0);
  };

  const handleFontSizeChange = (e) => {
    if (selectedContainer) {
      handleFontChange(selectedContainer, 'fontSize', `${e.target.value}px`);
      setFontSize(`${e.target.value}px`);
    }
  };

  const handleFontFamilyChange = (e) => {
    if (selectedContainer) {
      handleFontChange(selectedContainer, 'fontFamily', e.target.value);
      setFontFamily(e.target.value);
    }
  };

  const handleShapeColorChange = (color) => {
    if (selectedContainer) {
      const rgbaColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
      handleShapeChange(selectedContainer, 'color', rgbaColor);
      setShapeColor(rgbaColor);
      setShapeOpacity(color.rgb.a);
    }
  };

  const handleExposureChange = (e) => {
    if (selectedContainer) {
      setModelFiles((prev) => {
        const updatedFiles = [...prev];
        const index = updatedFiles.findIndex((file) => file.id === selectedContainer);
        updatedFiles[index].exposure = parseFloat(e.target.value);
        return updatedFiles;
      });
    }
  };

  const handleLightColorChange = (color) => {
    if (selectedContainer) {
      setModelFiles((prev) => {
        const updatedFiles = [...prev];
        const index = updatedFiles.findIndex((file) => file.id === selectedContainer);
        updatedFiles[index].lightColor = color.hex;
        return updatedFiles;
      });
    }
  };

  const handleEyedropper = async () => {
    if ('EyeDropper' in window) {
      const eyeDropper = new window.EyeDropper();
      try {
        const result = await eyeDropper.open();
        const newColor = result.sRGBHex;
        setShapeColor(newColor);
        handleShapeChange(selectedContainer, 'color', newColor);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('Eyedropper API is not supported in this browser.');
    }
  };

  const handleSelectLayer = (id) => {
    setSelectedContainer(id);
    handleObjectClick(id);
  };

  const handleRenameLayer = (id, newName) => {
    setLayers((prevLayers) =>
      prevLayers.map((layer) =>
        layer.id === id ? { ...layer, name: newName } : layer
      )
    );
  };

  const handleMoveLayer = (fromIndex, toIndex) => {
    const updatedLayers = [...layers];
    const [movedLayer] = updatedLayers.splice(fromIndex, 1);
    updatedLayers.splice(toIndex, 0, movedLayer);
    setLayers(updatedLayers);

    setBgFiles(reorderZIndex(bgFiles, updatedLayers));
    setModelFiles(reorderZIndex(modelFiles, updatedLayers));
    setTextBoxes(reorderZIndex(textBoxes, updatedLayers));
    setShapes(reorderZIndex(shapes, updatedLayers));
  };


  const handleToggleVisibility = (id, isVisible) => {
    const updateVisibility = (items, setItems) => {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? {
                ...item,
                isVisible,
                // If hiding, save current state; if showing, restore state
                position: isVisible && item.hiddenState ? item.hiddenState.position : item.position,
                size: isVisible && item.hiddenState ? item.hiddenState.size : item.size,
                rotation: isVisible && item.hiddenState ? item.hiddenState.rotation : item.rotation,
                hiddenState: !isVisible
                  ? {
                      position: item.position,
                      size: item.size,
                      rotation: item.rotation,
                    }
                  : item.hiddenState,
              }
            : item
        )
      );
    };
  
    updateVisibility(bgFiles, setBgFiles);
    updateVisibility(modelFiles, setModelFiles);
    updateVisibility(textBoxes, setTextBoxes);
    updateVisibility(shapes, setShapes);
  };
  
  

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="three-canvas-container" onMouseDown={handleCanvasClick}>
        <div className="navbar" style={{ width: '5%' }}>
            <div className="button-container">
              <button className="toggle-button toggle-button-1" onClick={() => toggleSidebar(1)} />
            </div>
            <div className="button-container">
              <button className="toggle-button toggle-button-2" onClick={() => toggleSidebar(2)} />
            </div>
            <div className="button-container">
              <button className="toggle-button toggle-button-3" onClick={() => toggleSidebar(3)} />
            </div>
            <div className="button-container">
              <button className="toggle-button toggle-button-4" onClick={() => toggleSidebar(4)} />
            </div>
            <div className="button-container">
              <button className="toggle-button toggle-button-5" onClick={() => toggleSidebar(5)} />
            </div>
            <div className="button-container">
              <button className="toggle-button toggle-button-6" onClick={() => toggleSidebar(6)} />
            </div>
        </div>

        <div
          ref={sidebarRef1}
          className={`three-canvas-sidebar ${activeSidebar === 1 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 1 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <div className="canvas-size-inputs-container">
            <div className="canvas-size-inputs">
              <label>
                Canvas Width (px):
                <input
                  type="text"
                  name="width"
                  value={canvasSize.width.replace('px', '')}
                  onChange={handleCanvasSizeChange}
                  onFocus={handleInputFocus}
                />
              </label>
              <label>
                Canvas Height (px):
                <input
                  type="text"
                  name="height"
                  value={canvasSize.height.replace('px', '')}
                  onChange={handleCanvasSizeChange}
                  onFocus={handleInputFocus}
                />
              </label>
            </div>
          </div>
          <div className="buttons-container">
            <button onClick={handleClearCanvas}>Clear Canvas</button>
            <button onClick={handleDownloadCanvas}>Export Canvas</button>
            
          </div>
        </div>

        <div
          ref={sidebarRef2}
          className={`three-canvas-sidebar-2 ${activeSidebar === 2 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 2 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <div className="buttons-container">
            <button onClick={() => bgInputRef.current.click()}>Upload Image</button>
            <input ref={bgInputRef} id="bgUpload" type="file" accept="image/*" multiple style={{ display: 'none' }} onChange={handleBgUpload} />
            <button 
      id="shutterstock-button" 
      onClick={() => window.open('https://www.shutterstock.com/', '_blank')}
    >
    </button>
          </div>
          <div className="scrollable-container">
            <div className='scrollable-header'>
              <h1>Shadow Library</h1>
            </div>            
            {imageButtons.map((image) => (
            <button
              key={image.id}
              className="image-button"
              onClick={() => handleImageButtonClick(image)}
              style={{ backgroundImage: `url(${image.thumbnailSrc})` }} 
            ></button>
              ))}
              </div>
            </div>
        <div
          ref={sidebarRef3}
          className={`three-canvas-sidebar-3 ${activeSidebar === 3 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 3 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <div className="buttons-container">
            <button onClick={() => modelInputRef.current.click()}>Upload Render .glb</button>
            <input ref={modelInputRef} id="modelUpload" type="file" accept=".glb" multiple style={{ display: 'none' }} onChange={handleModelUpload} />
          </div>
          {selectedContainer && modelFiles.some((model) => model.id === selectedContainer) && (
            <div className="model-customization-container" style={{ marginTop: '20px', width: '100%' }}>
              <label>
                Exposure:
                <input
                  type="range"
                  min="0"
                  max="2"
                  step="0.1"
                  value={modelFiles.find((model) => model.id === selectedContainer).exposure}
                  onChange={handleExposureChange}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
                <span>{modelFiles.find((model) => model.id === selectedContainer).exposure}</span>
              </label>
              <CustomChromePicker
                color={modelFiles.find((model) => model.id === selectedContainer).lightColor}
                onChange={handleLightColorChange}
                onChangeComplete={handleLightColorChange}
                onEyedropper={handleEyedropper}
              />
            </div>
          )}
        </div>

        <div
          ref={sidebarRef4}
          className={`three-canvas-sidebar-4 ${activeSidebar === 4 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 4 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <div className="buttons-container">
            <button onClick={addTextBox}>Add Text Box</button>
          </div>
          {selectedContainer && textBoxes.some((box) => box.id === selectedContainer) && (
            <div className="font-customization-container" style={{ marginTop: '20px' }}>
              <label>
                Font Size:
                <input
                  type="number"
                  value={parseInt(fontSize)}
                  onChange={handleFontSizeChange}
                  style={{ width: '100%', marginBottom: '10px' }}
                />
              </label>
              <label>
                Font Family:
                <select value={fontFamily} onChange={handleFontFamilyChange} style={{ width: '100%' }}>
                  <option value="Arial">Arial</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Courier New">Courier New</option>
                </select>
              </label>
              <div className="text-style-buttons" style={{ marginBottom: '10px' }}>
                <button
                  style={{
                    width: '48%',
                    marginRight: '4%',
                    padding: '10px',
                    fontWeight: 'bold',
                    backgroundColor: '#343637',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFontChange(selectedContainer, 'fontWeight', textBoxes.find(box => box.id === selectedContainer).fontWeight === 'bold' ? 'normal' : 'bold')}
                >
                  Bold
                </button>
                <button
                  style={{
                    width: '48%',
                    padding: '10px',
                    fontStyle: 'italic',
                    backgroundColor: '#343637',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFontChange(selectedContainer, 'fontStyle', textBoxes.find(box => box.id === selectedContainer).fontStyle === 'italic' ? 'normal' : 'italic')}
                >
                  Italic
                </button>
              </div>

              <CustomChromePicker 
                color={fontColor} 
                onChange={handleFontColorChange} 
                onChangeComplete={(color) => setTextOpacity(color.rgb.a)} 
                onEyedropper={handleEyedropper} 
              />
            </div>
          )}
        </div>
        <div
          ref={sidebarRef5}
          className={`three-canvas-sidebar-5 ${activeSidebar === 5 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 5 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <div className="buttons-container">
            <button onClick={() => addShape('square')}>Add Square</button>
            <button onClick={() => addShape('circle')}>Add Circle</button>
            <button onClick={() => addShape('triangle')}>Add Triangle</button>
          </div>
          {selectedContainer && shapes.some((shape) => shape.id === selectedContainer) && (
            <div className="shape-customization-container">
              <CustomChromePicker 
                color={shapeColor} 
                onChange={handleShapeColorChange} 
                onChangeComplete={(color) => setShapeOpacity(color.rgb.a)} 
                onEyedropper={handleEyedropper} 
              />
            </div>
          )}
        </div>
        <div
          ref={sidebarRef6}
          className={`three-canvas-sidebar-6 ${activeSidebar === 6 ? 'expanded' : ''}`}
          style={{ display: activeSidebar === 6 ? 'block' : 'none' }}
        >
          <div className="logo-container">
            <img src="VisualStudio_Logo.png" alt="Logo" />
          </div>
          <LayerPanel
            layers={layers}
            onSelectLayer={handleSelectLayer}
            onMoveLayer={handleMoveLayer}
            onRenameLayer={handleRenameLayer}
            onToggleVisibility={handleToggleVisibility}
          />
        </div>

        <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onExport={handleExport}
        />

        <div
          className="three-canvas-area-background"
          style={{
            width: activeSidebar ? '78%' : '95%',
            marginLeft: activeSidebar ? '22%' : '5%',
          }}
          onMouseDown={handleCanvasClick}
        >
          <div
            ref={captureDivRef}
            className="three-canvas-area"
            style={{
              width: canvasSize.width,
              height: canvasSize.height,
              transform: `scale(${zoom})`,
              transformOrigin: 'center',
              position: 'relative',
              backgroundColor: 'white',
              zIndex: '1', /* Ensure this is behind the sidebar and zoom slider */
              overflow: 'hidden',
              display: 'flex',  // Use flex to center child elements
              alignItems: 'center',
              justifyContent: 'center',
                /* border: 2px solid green; */
            }}
          >
            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
  {bgFiles.filter(bg => bg.isVisible !== false).map((bg, index) => {
    const refIndex = index;
    // Ensure the ref is initialized
    if (!moveableRefs.current[refIndex]) {
      moveableRefs.current[refIndex] = React.createRef();
    }

    return (
      <MoveableContainer
        key={bg.id}
        ref={moveableRefs.current[refIndex]}
        verticalGuidelines={verticalGuidelines}
        horizontalGuidelines={horizontalGuidelines}
        elementGuidelines={getElementGuidelines()}
        position={bg.position}
        setPosition={(pos) => {
          const newBgFiles = [...bgFiles];
          const idx = newBgFiles.findIndex((file) => file.id === bg.id);
          newBgFiles[idx].position = pos;
          setBgFiles(newBgFiles);
        }}
        size={bg.size}
        setSize={(size) => {
          const newBgFiles = [...bgFiles];
          const idx = newBgFiles.findIndex((file) => file.id === bg.id);
          newBgFiles[idx].size = size;
          setBgFiles(newBgFiles);
        }}
        isSelected={bg.isSelected}
        onClick={() => handleObjectClick(bg.id)}
        onMouseDown={(e) => e.stopPropagation()} 
        zIndex={bg.zIndex}
      >
        <img
          src={bg.url}
          alt={`background-${bg.id}`}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </MoveableContainer>
    );
  })}

  {modelFiles.filter(model => model.isVisible !== false).map((model, index) => {
    const refIndex = bgFiles.length + index;
    // Ensure the ref is initialized
    if (!moveableRefs.current[refIndex]) {
      moveableRefs.current[refIndex] = React.createRef();
    }

    return (
      <MoveableContainer
        key={model.id}
        ref={moveableRefs.current[refIndex]}
        verticalGuidelines={verticalGuidelines}
        horizontalGuidelines={horizontalGuidelines}
        elementGuidelines={getElementGuidelines()}
        position={model.position}
        setPosition={(pos) => {
          const newModelFiles = [...modelFiles];
          const idx = newModelFiles.findIndex((file) => file.id === model.id);
          newModelFiles[idx].position = pos;
          setModelFiles(newModelFiles);
        }}
        size={model.size}
        setSize={(size) => {
          const newModelFiles = [...modelFiles];
          const idx = newModelFiles.findIndex((file) => file.id === model.id);
          newModelFiles[idx].size = size;
          setModelFiles(newModelFiles);
        }}
        isSelected={model.isSelected}
        onClick={() => handleObjectClick(model.id)}
        onMouseDown={(e) => e.stopPropagation()} 
        zIndex={model.zIndex}
      >
        <Canvas
          ref={canvasRef}
          style={{ width: '100%', height: '100%', transformOrigin: 'center', transform: `scale(${1 / zoom})`, 
            }}
          gl={{ preserveDrawingBuffer: true }}
          

        >
          <GLBViewerContent
            fileUrl={model.url}
            scale={zoom}
            exposure={model.exposure}
            lightColor={model.lightColor}
            
          />
        </Canvas>
      </MoveableContainer>
    );
  })}

  {textBoxes.filter(textBox => textBox.isVisible !== false).map((textBox, index) => {
    const refIndex = bgFiles.length + modelFiles.length + index;
    // Ensure the ref is initialized
    if (!moveableRefs.current[refIndex]) {
      moveableRefs.current[refIndex] = React.createRef();
    }

    return (
      <MoveableContainer
  key={textBox.id}
  ref={moveableRefs.current[refIndex]}
  verticalGuidelines={verticalGuidelines}
  horizontalGuidelines={horizontalGuidelines}
  elementGuidelines={getElementGuidelines()}
  position={textBox.position}
  setPosition={(pos) => {
    const newTextBoxes = [...textBoxes];
    const idx = newTextBoxes.findIndex((box) => box.id === textBox.id);
    newTextBoxes[idx].position = pos;
    setTextBoxes(newTextBoxes);
  }}
  size={textBox.size}
  setSize={(size) => {
    const newTextBoxes = [...textBoxes];
    const idx = newTextBoxes.findIndex((box) => box.id === textBox.id);
    newTextBoxes[idx].size = size;
    setTextBoxes(newTextBoxes);
  }}
  isSelected={textBox.isSelected}
  onClick={() => handleObjectClick(textBox.id)}
  isTextbox={true}  // Specify that this is a textbox
  zIndex={textBox.zIndex}
>
  <textarea
    id={`textarea-${textBox.id}`}
    value={textBox.content}
    onChange={(e) => handleTextChange(textBox.id, e.target.value)}
    style={{
      lineHeight: '1.0',
      width: '100%',
      height: '100%',
      resize: 'none',
      backgroundColor: 'transparent',
      pointerEvents: 'auto',
      fontSize: textBox.fontSize,
      fontFamily: textBox.fontFamily,
      color: textBox.color,
      fontWeight: textBox.fontWeight || 'normal',
      fontStyle: textBox.fontStyle || 'normal',
    }}
    autoFocus={textBox.isEditing}
    onBlur={() => setTextBoxes((prev) =>
      prev.map((box) => ({
        ...box,
        isEditing: false,
      }))
    )}
  />
</MoveableContainer>
    );
  })}

  {shapes.filter(shape => shape.isVisible !== false).map((shape, index) => {
    const refIndex = bgFiles.length + modelFiles.length + textBoxes.length + index;
    // Ensure the ref is initialized
    if (!moveableRefs.current[refIndex]) {
      moveableRefs.current[refIndex] = React.createRef();
    }

    return (
      <MoveableContainer
        key={shape.id}
        ref={moveableRefs.current[refIndex]}
        verticalGuidelines={verticalGuidelines}
        horizontalGuidelines={horizontalGuidelines}
        elementGuidelines={getElementGuidelines()}
        position={shape.position}
        setPosition={(pos) => {
          const newShapes = [...shapes];
          const idx = newShapes.findIndex((s) => s.id === shape.id);
          newShapes[idx].position = pos;
          setShapes(newShapes);
        }}
        size={shape.size}
        setSize={(size) => {
          const newShapes = [...shapes];
          const idx = newShapes.findIndex((s) => s.id === shape.id);
          newShapes[idx].size = size;
          setShapes(newShapes);
        }}
        isSelected={shape.isSelected}
        onClick={() => handleObjectClick(shape.id)}
        onMouseDown={(e) => e.stopPropagation()} 
        zIndex={shape.zIndex}
      >
        {shape.type === 'square' && (
          <svg className="shape-square">
            <rect width="100%" height="100%" fill={shape.color} fillOpacity={shape.opacity} />
          </svg>
        )}
        {shape.type === 'circle' && (
          <svg className="shape-circle" viewBox="0 0 100 100" preserveAspectRatio="none">
            <circle cx="50" cy="50" r="50" fill={shape.color} fillOpacity={shape.opacity} />
          </svg>
        )}
        {shape.type === 'triangle' && (
          <svg className="shape-triangle" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="50,0 0,100 100,100" fill={shape.color} fillOpacity={shape.opacity} />
          </svg>
        )}
      </MoveableContainer>
    );
  })}
</div>

          </div>
        </div>
        <div
          className="zoom-slider-container"
          style={{
            width: activeSidebar ? '78%' : '95%',
          }}
        >
          <input
            type="range"
            min="0.1"
            max="1.5"
            step="0.01"
            value={zoom}
            onChange={handleZoomChange}
            className="zoom-slider"
          />
        </div>
      </div>
    </DndProvider>
  );
};

export default ThreeCanvas;